@import '../../styles/includes';

.DropdownContent {
    $root: &;
    @extend %container;
    display: none;

    @include media(ml) {
        position: sticky;
        top: 0;
        padding: 72px 16px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
    }

    &__Header {
        display: inline-flex;
        margin-bottom: 32px;
        font-family: $fontLight;
        font-size: 2rem;
        text-decoration: none;
        color: $colorGray90;
        text-transform: uppercase;
        will-change: color, stroke;
        transition: color 0.25s ease;
    }

    a.DropdownContent__Header:hover {
        color: $colorRed50;

        svg * {
            stroke: $colorRed50;
        }
    }

    &__Icon {
        transform: rotate(-90deg);
        margin-left: 16px;
    }

    &__NoHeader {
        margin-top: 20px;
    }

    &__List {
        display: grid;
        flex-direction: column;
        row-gap: 32px;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    &__Link {
        font-family: $fontLight;
        font-size: 2rem;
        padding: 0;
        background-color: $colorWhite;
        color: $colorGray90;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.25s ease, color 0.25s ease;
        text-underline-offset: 6px;
        text-decoration-thickness: 1px;

        @media (hover: hover) {
            &:hover {
                color: $colorRed50;
                text-decoration-color: $colorRed50;
                cursor: pointer;
            }
        }
    }

    &__Item {
        font-family: $fontLight;
        font-size: 2rem;
        padding: 0;
        background-color: $colorWhite;
        color: $colorGray90;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.25s ease;
        text-underline-offset: 6px;

        &:hover {
            color: $colorRed50;
            text-decoration-color: $colorRed50;
            cursor: pointer;
        }
    }

    &__Nav {
        color: red;
        transform: translateY(-16px);
        opacity: 0;
        will-change: opacity, transform;
        transition: transform 0.25s 0.15s ease, opacity 0.25s 0.15s ease;

        #{$root}--Active & {
            transform: translateY(0);
            opacity: 1;
        }

    }
}
