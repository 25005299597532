@import '../../styles/variables';
@import '../../styles/includes';

.MobileHeader {
    $root: &;

    overflow: hidden;
    position: relative;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $colorWhite;
    padding: 0 20px;
    z-index: 99999;
    box-shadow: 0px 1px 1px rgba($colorGray30, 1);
    transition: box-shadow 0.25s ease;

    &--Unpinned {
        box-shadow: 0px 1px 1px rgba($colorGray30, 0);
    }

    &--Open {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    &__MobileMenuWrapper {
        display: flex;
        flex-direction: row;
    }

    &__Button {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: transparent;
        cursor: pointer;
        z-index: 10;
    }

    &__HamburgerMenu {
        position: relative;
        display: block;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    &__Line1 {
        display: block;
        position: absolute;
        top: 2px;
        height: 3px;
        width: 100%;
        background-color: $colorBlack;
        border-radius: 2px;
        transition: transform 0.2s ease-in-out;
    }

    &__Line2 {
        display: block;
        position: absolute;
        top: 14px;
        height: 3px;
        width: 100%;
        background-color: $colorBlack;
        border-radius: 2px;
        transition: transform 0.2s ease-in-out;
    }

    &__Line3 {
        display: block;
        position: absolute;
        top: 26px;
        right: 0;
        height: 3px;
        width: 80%;
        background-color: $colorBlack;
        border-radius: 2px;
        transition: transform 0.2s ease-in-out;
    }

    #{$root}--Open &__Line1 {
        transform: rotate(45deg) translate(10px, 5px);
        transition: transform 0.2s ease-in-out;
    }

    #{$root}--Open &__Line2 {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    #{$root}--Open &__Line3 {
        width: 100%;
        transform: rotate(-45deg) translate(11px, -7px);
        transition: transform 0.2s ease-in-out;
    }

    &__SearchButton {
        margin-right: 33px;
    }

    &__MobileMenu {
        position: absolute;
        top: 75px;
        left: 0;
        padding-top: 56px;
        width: 100%;
        height: 100vh;
        background-color: $colorWhite;
        border-top: solid 1px $colorGray30;
        transform: translateX(100%);
        transition: all 0.25s ease;
        opacity: 0;

        #{$root}--Open & {
            opacity: 1;
            transform: translateX(0);
            transition: all 0.25s ease;
            z-index: 99;
        }
    }

    &__MobileMenuItems {
        border-top: solid 1px $colorGray30;
    }

    &__ListItem {
        position: relative;
        border-bottom: solid 1px $colorGray30;
    }

    &__Link {
        display: flex;
        align-items: center;
        padding: 17px 24px;
        color: $colorBlack;
        text-decoration: none;
        font-size: 1.8rem;
        font-family: $fontLight;
        cursor: pointer;
        transition: color 0.3s;
    }

    &__AccordionHeading {
        padding: 17px 24px;
        font-family: $fontLight;
        font-size: 1.8rem;
        cursor: pointer;
        transition: color 0.3s;
    }

    &__AccordionButton {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    &__ToggleButton {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 2px;
            background-color: $colorBlack;
            transition: transform 0.2s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2px;
            height: 16px;
            background-color: $colorBlack;
            transition: transform 0.2s ease-in-out;
        }

        &.active::before {
            transform: translate(-50%, -50%) rotate(0deg);
        }

        &.active::after {
            transform: translate(-50%, -50%) rotate(-90deg);
        }
    }

    &__AccordionItemPanel {
        background-color: $colorGray5;
        border-top: solid 1px $colorGray30;
    }

    &__SubListContainer {
        height: 0;
        opacity: 0;

        #{$root}--SubLinksOpen & {
            height: 100%;
            opacity: 1;
            transition: all 0.3s;
        }
    }

    &__SubListItems {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background-color: $colorGray5;
        opacity: 0;

        #{$root}--SubLinksOpen & {
            opacity: 1;
            transition: all 0.3s;
        }
    }

    &__SubListItem {
        padding: 18px 32px;
    }

    &__MainLink {
        display: flex;
        color: $colorGray90;
        text-decoration: none;
        font-size: 1.6rem;
        font-family: $fontLight;
        text-transform: uppercase;
        will-change: color stroke;
        transition: color 0.25s ease;
    }

    &__Icon {
        display: flex;
        align-content: center;
        height: 12px;

        svg {
            margin-left: 16px;
            transform: rotate(-90deg);
        }
    }

    &__SubLink {
        margin-left: 8px;
        color: $colorBlack;
        text-decoration: none;
        font-size: 1.6rem;
        font-family: $fontLight;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.25s ease;
        text-underline-offset: 6px;
    }

    &__Contact {
        display: flex;
        justify-content: space-evenly;
        column-gap: 8px;
        margin: 56px 0 0 0;
        padding: 16px 0;
        background-color: $colorGray5;
    }

    &__ContactLink {
        color: $colorGray90;
        text-decoration: none;
        font-size: 1.6rem;
        font-family: $fontLight;
        transition: color 0.3s;
    }
}