@import 'styles/includes';

.Icon {
    display: flex;
    align-self: center;
    align-content: center;
    flex-shrink: 0;

    svg {
        width: 100%;
        height: 100%;
        max-width: 24px;
        max-height: 24px;
    }

    svg:not(:root) {
        width: 100%;
        height: auto;
    }

    &--Xsmall {
        svg {
            max-width: 14px;
            max-height: 14px;
        }
    }

    &--Small {
        svg {
            max-width: 15px;
            max-height: 15px;
        }
    }

    &--Medium {
        svg {
            max-width: 18px;
            max-height: 18px;
        }
    }

    &--Large {
        svg {
            max-width: 26px;
            max-height: 26px;
        }
    }

    &--Xlarge {
        svg {
            max-width: 55px;
            max-height: 55px;
        }
    }

    &--Xxlarge {
        svg {
            max-width: 87px;
            max-height: 87px;
        }
    }

    &--Xxxlarge {
        svg {
            max-width: 140px;
            max-height: 140px;
        }
    }

    &--Xxxxlarge {
        svg {
            max-width: 240px;
            max-height: 240px;
        }
    }

    &--White {
        svg path {
            fill: $colorWhite;
        }
    }

    &--Black {
        svg path {
            fill: $colorBlack;
        }
    }
}
