@import 'styles/includes';

.Footer {
    $root: &;

    width: 100%;
    border-top: solid 0.5px $colorGray30;
    padding: 48px 0 24px 0;
    color: #3b3b3b;

    &__Logo {
        display: inline-block;
    }

    @include media(m) {
        padding: 56px 0;
    }

    &__Container {
        @extend %container;
    }

    &__Content {
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
        }
    }

    &__Column {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        border-top: solid 0.5px $colorGray30;

        @include media(m) {
            flex-direction: row;
            width: calc(100% / 3);
        }

        &--Logo {
            display: flex;
            align-items: center;
            border-top: none;
        }

        &--Links {
            border-top: none;
            padding: 0 24px;

            @include media(m) {
                padding: 0;
            }
        }

        &--Social {
            padding: 24px 24px 0 24px;

            @include media(m) {
                padding: 0;
                flex-direction: column;
                border-top: none;
            }
        }

        &--Desktop {
            display: none;

            @include media(m) {
                display: flex;
                align-items: center;
            }
        }

        &--Mobile {
            display: flex;
            margin: 48px 0;

            @include media(m) {
                display: none;
                margin: 0;
            }
        }
    }

    &__LinkList {
        display: flex;
        flex-wrap: wrap;

        &--Links {
            :nth-child(even) {
                padding-left: 48px;

                @include media(m) {
                    padding-left: 0;
                }
            }
        }

        &--Social {
            @include media(m) {
                flex-wrap: nowrap;
                flex-direction: column;
            }

            :nth-child(even) {
                padding-left: 16px;

                @include media(m) {
                    padding-left: 0;
                }
            }
        }
    }

    &__LinkListItem {
        width: 50%;
        margin-bottom: 24px;
    }

    &__SocialLink,
    &__Link {
        font-family: $fontLight;
        font-size: 1.6rem;
        line-height: 1.25;
        text-underline-offset: 4px;
        text-decoration-color: transparent;
        text-decoration-line: underline;
        text-decoration-thickness: 1px;

        @include media(m) {
            font-size: 2rem;
        }

        @media (hover: hover) {
            will-change: color, text-decoration-color;
            transition: color 0.25s ease, text-decoration-color 0.25s ease;

            &:hover {
                color: $colorRed50;
                text-decoration-color: $colorRed50;
            }
        }
    }

    &__SoMeIcon {
        width: 32px;
        display: flex;
        justify-content: flex-start;
        margin-right: 4px;
    }

    &__SocialLink {
        display: inline-flex;
        align-items: center;

        svg {
            margin-right: 8px;

            path {
                fill: $colorRed50;
            }
        }
    }

    &__SearchContainer {
        padding: 24px 24px 0 24px;
        border-top: 1px solid $colorGray30;
        margin: 0 0 0 -24px;
        width: calc(100% + 48px);

        @include media(m) {
            width: calc(100% - 40px);
            margin: 0 0 24px 0;
            padding: 0;
            border-top: none;
        }
    }

    &__Search {
        width: 100%;
        position: relative;
    }

    &__SearchField {
        display: block;
        border: 1px solid $colorGray60;
        background-color: $colorWhite;
        padding: 24px 16px 9px 16px;
        width: 100%;
        font-size: 1.6rem;
        font-family: $fontRegular;
        color: $colorGray90;

        &:focus {
            +#{$root}__Placeholder {
                font-size: 1.1rem;
                top: 8px;
            }
        }
    }

    &__Placeholder {
        transition: all 0.25s ease;
        pointer-events: none;
        font-size: 1.6rem;
        position: absolute;
        top: 18px;
        left: 16px;
    }

    &__Submit {
        background-color: $colorWhite;
        border: none;
        position: absolute;
        right: 12px;
        top: 12px;
    }

    &__BottomRow {
        display: flex;
        flex-direction: column;

        @include media(m) {
            margin-top: 24px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__Copyright {
        text-align: center;
        font-size: 1.4rem;

        @include media(m) {
            text-align: right;
        }
    }

    &__CookieSettings {
        cursor: pointer;
        border-radius: 4px;
        color: $colorBlack;
        border: 1px solid $colorBlack;
        background-color: $colorWhite;
        padding: 8px 16px;
        font-size: 1.4rem;
        display: block;
        font-family: $fontMedium;
        transition: background-color 0.15s ease;
        margin-bottom: 8px;

        @include media(m) {
            margin-bottom: 0;
            display: inline-block;
        }
        
        @media (hover: hover) {
            &:hover {
                background-color: $colorGray10;
            }
        }
    }
}