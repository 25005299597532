@import '../../styles/includes';

.Header {

    $root: &;
    display: none;

    @include media(ml) {
        display: block;
        position: relative;
        z-index: 10;
    }

    &__Wrap {
        @include media(ml) {
            position: relative;
            display: flex;
            justify-content: space-between;
            background-color: $colorWhite;
            box-shadow: 0px 1px 1px rgba($colorGray30, 1);
        }
    }


    &__Container {
        @extend %container;
        display: flex;
        column-gap: 200px;
        align-items: center;
        width: 100%;
        height: 124px;
    }

    &__Logo {
        position: relative;
        top: 8px;
        display: flex;
        justify-content: flex-start;
        transition: margin-top 0.2s ease;
        margin-top: 0;

        #{$root}--Unpinned & {
            margin-top: -32px;
        }
    }

    &__Navigation {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
    }

    &__Menu {
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 42px;
    }

    &__Dropdown {
        position: relative;
        display: inline-block;

        &:hover {
            .Header__DropDownContent {
                display: flex;
            }
        }
    }

    &__DropdownButton {
        color: $colorBlack;
        font-size: 2rem;
        font-family: $fontLight;
        border: none;
        display: flex;
        align-items: center;
        background-color: transparent;
        padding-bottom: 22px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $colorRed50;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease;
        }

        &--Active, &:focus {
            &::after {
                transform: scaleX(1);
            }
        }

        &:hover {
            cursor: pointer;

            &::after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }

    &__Icon {
        transform: rotate(0);
        will-change: transform;
        transition: transform 0.3s ease;
        margin-left: 8px;

        &--Active {
            transform: rotate(180deg);
        }
    }

    &__MenuLink {
        position: relative;
        display: block;
        font-size: 2rem;
        font-family: $fontLight;
        text-decoration: none;
        color: black;
        padding: 1px 1px 22px 1px;

        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $colorRed50;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease;
        }

        &--Active, &:hover {
            cursor: pointer;

            &::after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }

    &__ContactNav {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__ContactMenu {
        position: relative;
        display: flex;
        flex-direction: row;
        column-gap: 32px;
        padding: 9px 0;
        margin-right: 90px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100vw;
            max-width: calc(100% + 9rem);
            height: 1px;
            background-color: $colorRed50;
        }
    }

    &__ContactLink {
        font-size: 1.2rem;
        text-decoration: none;
        color: $colorBlack;
        font-family: $fontLight;
        line-height: 1.68;
        transition: color 0.3s ease;

        &--Active, &:hover {
            color: $colorRed50;
        }
    }

    &__DropdownContent {
        position: absolute;
        left: 0;
        right: 0;
        top: -150vh;
        background-color: $colorWhite;
        z-index: -1;
        transition: top 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
        will-change: top, box-shadow;
        box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.0);

        &--Active {
            top: 124px;
            box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
        }
    }

    &__MobileHeader {
        position: relative;
        display: block;
        width: 100%;
        top: 0;
        z-index: 99;

        @include media(ml) {
            display: none;
        }
    }
}
